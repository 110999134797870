
import { Component, Watch } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import {
  IPaymentMethodListRequest,
  IPaymentMethod
} from "@/types/payment_method";
import { IPagination } from "@/types";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // payment_method
  @Action("payment_method/adminGetList")
  public getList!: (request: IPaymentMethodListRequest) => boolean;

  @Getter("payment_method/list")
  public paymentMethodList!: IPaymentMethod[];

  @Getter("payment_method/pagination")
  public pagination!: IPagination<IPaymentMethod[]>;

  @Mutation("payment_method/clear")
  public clear!: () => void;

  // watch
  @Watch("$route.query")
  public async changeQuery() {
    this.queryToParams();
    await this.getList(this.params);
  }

  // パラメータ定義
  public params: IPaymentMethodListRequest = {
    page: 1
  };

  //
  // variables
  //
  public headers = [{ text: "支払方法", value: "name", sortable: false }];

  //
  // methods
  //
  private async created() {
    this.clear();
    this.queryToParams();

    await this.getList(this.params);
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const query = this.$route.query;
    this.params = {
      page: query.page ? Number(query.page) : 1
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.params.page = 1;
    await this.paramsToQuery();
  }

  // URLのパラメータをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          page: this.params.page ? String(this.params.page) : "1"
        }
      })
      .catch(() => {});
  }

  // 行をクリックした際の挙動
  public rowClick(payment_method: IPaymentMethod) {
    this.$router.push(`/admin/payment_method/${payment_method.id}/edit`);
  }
}
